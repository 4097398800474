<template>
  <!-- 注册简单表单组件 -->
  <div class="register-simple-form">
    <!-- 标题 -->
    <div class="form-titles">
      <p>
        已有帐号?
        <a @click="$store.commit('changeShowLogin', true)">立即登录</a>
      </p>
    </div>

    <!-- 表单 -->
    <div class="form-container">
      <el-form ref="form" :model="form" :rules="rules">
        <!-- <el-form-item prop="username">
          <el-input
            v-model.trim="form.username"
            placeholder="用户名"
          ></el-input>
        </el-form-item> -->
        <el-form-item prop="mobile">
          <el-input
            ref="phoneInput"
            v-model="form.mobile"
            placeholder="手机号"
            autocomplete="on"
          ></el-input>
        </el-form-item>

        <el-form-item prop="code">
          <div class="getCode">
            <el-input v-model="form.code" placeholder="短信验证码"></el-input>
            <el-button plain :disabled="codeSet.waitSend" @click="sendCode">{{
              codeSet.btnText
            }}</el-button>
          </div>
        </el-form-item>

        <el-form-item prop="password">
          <el-input
            v-model.trim="form.password"
            placeholder="密码"
            show-password
          ></el-input>
        </el-form-item>

        <el-form-item prop="checkPass">
          <el-input
            v-model.trim="form.checkPass"
            placeholder="确认密码"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>

      <el-button type="primary" class="subBtn" @click="submit"
        >立即注册</el-button
      >
    </div>
  </div>
</template>

<script>
import { getVerifyCode, checkVerifyCode, checkMobile } from "@/api/system";

export default {
  name: "registerSimpleForm",
  props: {
    form: {
      type: Object,
      default() {
        return {};
      },
    }, // 表单数据
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.form.checkPass !== "") {
          this.$refs.form.validateField("checkPass");
        }
        callback();
      }
    }; // 输入密码

    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    }; // 再次输入密码

    var mobilePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        checkMobile(this.form.mobile)
          .then((res) => {
            const doState = {
              WAITING: new Error("手机号审核中"),
              PASS: new Error("手机号已存在"),
            };
            callback(doState[res.data.state]);
          })
          .catch((err) => {
            err.response.status == 404 ? callback() : null;
          });
      }
    }; // 确认手机号存在

    var codePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else {
        checkVerifyCode({
          ...this.form,
          captcha: this.form.code,
          type: "SIGN_UP",
        })
          .then((res) => {
            callback();
          })
          .catch((err) => {
            callback(new Error("验证码错误"));
          });
      }
    }; // 确认验证码

    return {
      rules: {
        mobile: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: "blur",
          },
          {
            pattern: /^(?:(?:\+|00)86)?1\d{10}$/,
            message: "请输入正确的手机号码",
            trigger: ["blur", "change"],
          },
          {
            validator: mobilePass,
            trigger: "none",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: ["blur", "change"],
          },
          {
            validator: codePass,
            trigger: "none",
          },
          // {
          //   min: 6,
          //   max: 6,
          //   message: "长度在 6 个字符",
          //   trigger: "change",
          // },
        ],
        username: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
          },
          {
            validator: validatePass,
            trigger: "blur",
          },
          {
            min: 8,
            max: 20,
            message: "长度在 8 到 20 个字符",
            trigger: "change",
          },
        ],
        checkPass: [
          {
            required: true,
            message: "请再次输入密码",
          },
          {
            validator: validatePass2,
            trigger: "change",
          },
          {
            min: 8,
            max: 20,
            message: "长度在 8 到 20 个字符",
            trigger: "change",
          },
        ],
        email: [
          {
            required: true,
            message: "请输入邮箱地址",
            trigger: "blur",
          },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: "change",
          },
        ],
      }, // 校验规则
      codeSet: {
        waitSend: false, // 等待再次发送
        btnText: "发送验证码",
      }, // 验证码设置
    };
  },
  methods: {
    sendCode() {
      this.codeSet.waitSend = true;

      this.$refs.form.validateField("mobile", (err) => {
        if (!err) {
          let num = 60;
          let timer = setInterval(() => {
            if (num >= 1) {
              this.codeSet.btnText = `重新发送(${num--}s)`;
            } else {
              clearInterval(timer);
              this.codeSet.waitSend = false;
              this.codeSet.btnText = `发送验证码`;
            }
          }, 1000);

          getVerifyCode({ mobile: this.form.mobile, type: "SIGN_UP" });
        } else {
          this.codeSet.waitSend = false;
        }
      });
    }, // 发送验证码
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit("nextStep", this.form);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }, // 提交
  },
};
</script>

<style lang="less" scoped>
.register-simple-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1000px;
  margin: 0 auto;
  padding: 60px 0;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

  // 标题
  .form-titles {
    width: 410px;
    text-align: right;
    margin-bottom: 20px;

    a {
      color: var(--button-color_blue);
    }
  }

  // 表单容器
  .form-container {
    width: 410px;
    text-align: left;

    .el-form {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .el-form-item {
        width: 100% !important;
        margin-bottom: 20px;
        width: 320px;
        text-align: left;

        /deep/ .el-form-item__label {
          line-height: 1;
        }

        .getCode {
          display: flex;

          button {
            margin-left: 10px;
            border-color: var(--button-color_blue);
            color: var(--button-color_blue);

            &.is-disabled {
              min-width: 130px;
              border-color: #dcdfe6;
              color: var(--sub-title_gray);
            }
          }
        }
      }
    }

    .subBtn {
      width: 100%;
    }
  }
}
</style>
