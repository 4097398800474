<template>
  <div class="container">
    <svg-icon class="icon" icon-class="rf-succeed" />
    <p v-text="word" />
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "申请提交成功，我们会尽快与您联系，请耐心等待！",
    },
  },
  data() {
    return {
      num: 3,
    };
  },
  created() {
    let timer = setInterval(() => {
      if (this.num == 0) {
        this.$router.replace("/");
        clearInterval(timer);
      }
      this.num--;
    }, 1000);
  },
  computed: {
    word() {
      return `${this.text} ${this.num}秒后返回首页`;
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 330px;
  text-align: center;

  .icon {
    font-size: 56px;
    color: #5cc871;
  }

  p {
    font-size: 16px;
    margin-top: 14px;
  }
}
</style>
