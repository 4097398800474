<template>
  <div>
    <!-- 介绍 -->
    <product-brief
      height="270"
      :title="productBrief && productBrief.title[currentStep]"
      :memo="productBrief && productBrief.memo"
      :img-src="productBrief && productBrief.imgSrc"
    />

    <!-- 注册 -->
    <register-simple-form
      :form="form"
      v-show="currentStep == 'first'"
      @nextStep="currentStep = 'second'"
    />

    <partner-form
      v-show="currentStep == 'second'"
      showBack
      :form="form"
      :showTitle="false"
      :showMobile="false"
      :showIssue="false"
      @goBack="currentStep = 'first'"
      @validSuccess="validSuccess"
    />

    <regSuccess
      v-if="currentStep == 'third'"
      text="注册申请提交成功,我们会尽快审核,请耐心等待!"
    />
  </div>
</template>

<script>
import partnerForm from "@/components/local/partner-form";
import registerSimpleForm from "./components/register-simple-form";
import regSuccess from "@/views/applyTrial/components/reg-success";
import { register } from "@/api/system";

export default {
  components: {
    regSuccess,
    partnerForm,
    registerSimpleForm,
  },
  data() {
    return {
      productBrief: {
        title: {
          first: "欢迎注册帐号",
          second: "还差一步,新用户注册资料完善",
          third: "注册申请已提交",
        },
        memo: [],
        imgSrc: "https://s1.ax1x.com/2020/07/27/aPrmM8.png",
      }, // 介绍组件数据
      currentStep: "first", // 当前第几步
      form: {}, // 表单数据
    };
  },
  methods: {
    validSuccess(form) {
      console.log("验证通过", form);
      register(form).then((res) => {
        console.log(res);
        this.currentStep = "third";
      });
    }, // 验证通过
  },
};
</script>

<style lang="less" scoped>
.partner-form,
.register-simple-form {
  transform: translateY(-10%);
}
</style>
